
import { Resource } from '../api/src/models/Resource';

const resourceName = Resource.ResourceName.enum.Aether;

export default {
  name: 'EffectCustomListItem',
  props: {
    effectCustom: {
      type: Object,
      default() {
        return undefined;
      },
    },
  },
  data() {
    return {
      resourceName,
    };
  },
};
